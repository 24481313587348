import $ from "jquery";
import { addYears, format } from "date-fns";
import Plotly from "plotly.js-dist-min";

var CTAPT = 0,
  TXA = 0;
var CTCTA = 0;
var TDM = 0;
var BN = 0;
var IP = 0;
var DASSU = 0;
var CHRA = 0;
var VBAPT = 0;
var TSyn = [];
var CF = [],
  CM = [],
  data = [],
  data2 = [],
  data3 = [],
  data4 = [],
  data5 = [],
  data6 = [],
  layout = {};

const valeur_at = (event) => {
  const v1 = document.getElementById("input1").value;
  const v2 = document.getElementById("input5").value;
  let v3 = v2 / v1;
  $("#input2").val(v3.toFixed(2));
};

function Frais_notr() {
  const v2 = document.getElementById("input5").value;
  let v3 = (v2 * 7) / 100 + 1379;
  $("#input6").val(v3.toFixed(2));
}

const CT = (event) => {
  const v1 =
    ($("#input6").val() !== "" ? parseFloat($("#input6").val()) : 0) +
    ($("#input8").val() !== "" ? parseFloat($("#input8").val()) : 0) +
    ($("#input5").val() !== "" ? parseFloat($("#input5").val()) : 0) +
    ($("#input9").val() !== "" ? parseFloat($("#input9").val()) : 0);

  $("#inputCT1").val(parseFloat(v1).toFixed(2));
};

function PDM() {
  const v1 =
    (document.getElementById("input3").value !== ""
      ? parseInt(document.getElementById("input3").value)
      : 0) *
    (document.getElementById("input4").value !== ""
      ? parseInt(document.getElementById("input4").value)
      : 0);
  $("#inputPDM1").val(v1.toFixed(2));
}

function put() {
  var res = {};
  for (let i = 1; i < 33; i++) {
    res["const" + i] =
      document.getElementById("input" + i).value !== ""
        ? document.getElementById("input" + i).value
        : 0;
  }

  var obj = {
    surface_avant_travaux: res["const1"],
    valeur_metre: res["const2"],
    surface_apres_travaux: res["const3"],
    valeur_marche: res["const4"],
    prix_de_vente: res["const5"],
    frais_de_notaire: res["const6"],
    frais_encheres: res["const7"],
    autres_frais_achats: res["const8"],
    frais_travaux: res["const9"],
    prix_mobilier: res["const32"],
    charge_copropriete: res["const10"],
    entretien: res["const11"],
    assurance: res["const12"],
    diagnostic_immobilier: res["const13"],
    electricite_commun: res["const14"],
    eaux: res["const15"],
    inscription_greffe: res["const16"],
    declaration_journal_officiel: res["const17"],
    taxe_fonciere: res["const18"],
    date_emprunt: res["const19"],
    taux_emprunt: res["const20"],
    taux_assurance: res["const21"],
    duree_emprunt: res["const22"],
    apport: res["const23"],
    mois_decalage1: res["const24"],
    date_emprunt2: res["const25"],
    montant_emprunt: res["const26"],
    taux_emprunt2: res["const27"],
    taux_assurance2: res["const28"],
    duree_emprunt2: res["const29"],
    nbr_versement: res["const30"],
    mois_decalage2: res["const31"],
  };

  $.ajax({
    type: "PUT",
    data: JSON.stringify(obj),
    contentType: "application/json",
    url: "http://172.16.110.207:7000/db/add/liste/",
    success: function (data) {
      console.log("success");
      console.log(JSON.stringify(data));
      //window.location.reload();
    },
  });
}

function Calc() {
  const v1 =
    (document.getElementById("input6").value !== ""
      ? parseInt(document.getElementById("input6").value)
      : 0) +
    (document.getElementById("input8").value !== ""
      ? parseInt(document.getElementById("input8").value)
      : 0) +
    (document.getElementById("input5").value !== ""
      ? parseInt(document.getElementById("input5").value)
      : 0);

  const FSA =
    (document.getElementById("input6").value !== ""
      ? parseInt(document.getElementById("input6").value)
      : 0) +
    (document.getElementById("input8").value !== ""
      ? parseInt(document.getElementById("input8").value)
      : 0);

  const FT = $("#input9").val() !== "" ? $("#input9").val() : 0;

  const TA =
    document.getElementById("input21").value !== ""
      ? parseFloat(document.getElementById("input21").value)
      : 0;
  const TEMP =
    document.getElementById("input20").value !== ""
      ? parseFloat(document.getElementById("input20").value)
      : 0;
  const APRT =
    document.getElementById("input23").value !== ""
      ? parseFloat(document.getElementById("input23").value)
      : 0;

  const ME = v1 - APRT + parseInt(FT);
  DASSU = (ME * TA) / 100 / 12;
  IP = (ME * TEMP) / 100 / 12;

  const DE =
    document.getElementById("input22").value !== ""
      ? parseInt(document.getElementById("input22").value)
      : 0;

  const NDM = DE * 12;

  const FRD =
    document.getElementById("input17").value !== ""
      ? parseFloat(document.getElementById("input17").value)
      : 0;
  const CGAR =
    document.getElementById("input16").value !== ""
      ? parseFloat(document.getElementById("input16").value)
      : 0;
  const CTASSU = ((ME * TA) / 100) * DE;

  const v2 = (ME * TEMP) / 1200;
  const v3 = 1 + TEMP / 1200;
  const MPIA = v2 / (1 - Math.pow(v3, -DE * 12)) + DASSU;
  const CDCHA = parseFloat(MPIA * DE * 12 - ME - CTASSU);
  const CDCI = parseFloat(CDCHA) + parseFloat(CTASSU);

  const dt1 =
    $("#input19").val() !== ""
      ? format(
          addYears(new Date($("#input19").val()).getTime(), DE),
          "yyyy-MM-dd"
        )
      : 0;

  const MEC = $("#input26").val() !== "" ? parseFloat($("#input26").val()) : 0;
  const TEC =
    $("#input27").val() !== "" ? parseFloat($("#input27").val()) : 0.0001;
  const TAC = $("#input28").val() !== "" ? parseFloat($("#input28").val()) : 0;
  const DEC = $("#input29").val() !== "" ? parseInt($("#input29").val()) : 1;
  const v4 = (MEC * TEC) / 1200;
  const v5 = 1 + TEC / 1200;
  const MAC = (MEC * TAC) / 1200;
  const MPCA = v4 / (1 - Math.pow(v5, -DEC * 12)) + MAC;
  TDM = parseFloat(MPCA) + parseFloat(MPIA);
  const CTAC = (MEC * TAC * DEC) / 100;
  const CDCHAC = parseFloat(MPCA * DEC * 12) - MEC - CTAC;
  const CDCC = parseFloat(CTAC) + parseFloat(CDCHAC);
  const CTDC = parseFloat(CDCC) + parseFloat(CDCI);

  const dt2 =
    $("#input25").val() !== ""
      ? format(
          addYears(new Date($("#input25").val()).getTime(), DEC),
          "yyyy-MM-dd"
        )
      : 0;
  const NDMC = DEC * 12;
  // col 3 //

  const NMD1 = $("#input24").val() !== "" ? $("#input24").val() : 0;
  const NMD2 = $("#input31").val() !== "" ? $("#input31").val() : 0;
  const CDI = ((ME * TEMP) / 1200) * NMD1;

  const CDA = ((ME * TA) / 1200) * NMD1;

  const CTDAR = parseFloat(CDA) + parseFloat(CDI);

  const CDIC = ((MEC * TEC) / 1200) * NMD2;
  const CDAC = ((MEC * TAC) / 1200) * NMD2;

  const CTDARC = parseFloat(CDAC) + parseFloat(CDIC);
  const CTDEP = parseFloat(CTDAR) + parseFloat(CTDARC);

  const SAT = $("#input1").val() !== "" ? $("#input1").val() : 0;
  const VMM = $("#input4").val() !== "" ? $("#input4").val() : 0;
  const PA = $("#input5").val() !== "" ? $("#input5").val() : 0;
  const SAPT = $("#input3").val() !== "" ? $("#input3").val() : 0;

  const VBEM = parseInt(SAT) * parseInt(VMM);
  CTAPT = (parseInt(v1) + parseInt(FT)).toFixed(2);

  const ECA = parseInt(VBEM) - parseInt(CTAPT);
  VBAPT = parseInt(SAPT) * parseInt(VMM);
  const PVE = parseInt(VBAPT) - parseInt(v1);
  const PVAT = parseInt(PVE) - parseInt(FT);

  const IPV = parseInt(PVAT) * 0.3;
  BN = parseInt(PVAT) - parseFloat(IPV);

  const CCP = $("#input10").val() !== "" ? parseFloat($("#input10").val()) : 0;
  const ETRT = $("#input11").val() !== "" ? parseFloat($("#input11").val()) : 0;
  const ASRC = $("#input12").val() !== "" ? parseFloat($("#input12").val()) : 0;
  const ELC = $("#input14").val() !== "" ? parseFloat($("#input14").val()) : 0;
  const O = $("#input15").val() !== "" ? parseFloat($("#input15").val()) : 0;
  const TXF = $("#input18").val() !== "" ? parseFloat($("#input18").val()) : 0;

  TXA = TXF;
  CHRA = CCP + ETRT + ASRC + ELC + O;

  $("#td1").html(document.getElementById("input1").value);
  $("#td2").html(document.getElementById("input2").value);
  $("#td3").html(document.getElementById("input3").value);
  $("#td4").html(document.getElementById("input4").value);
  $("#td5").html(document.getElementById("input5").value);
  $("#td6").html(
    document.getElementById("input3").value *
      document.getElementById("input4").value
  );
  $("#td7").html(document.getElementById("input5").value);
  $("#td8").html(FSA);
  $("#td9").html(document.getElementById("input6").value);
  $("#FDN1").html(document.getElementById("input6").value);
  // $("#td10").html(document.getElementById("input7").value !== "" ? document.getElementById("input7").value : 0);
  $("#td11").html(
    document.getElementById("input8").value !== ""
      ? document.getElementById("input8").value
      : 0
  );
  $("#td12").html(v1);

  $("#td13").html(
    document.getElementById("input9").value !== ""
      ? parseInt(document.getElementById("input9").value)
      : 0
  );
  $("#td14").html(CTAPT);
  $("#td26").html(TXA);
  $("#td16").html(
    document.getElementById("input10").value !== ""
      ? parseInt(document.getElementById("input10").value)
      : 0
  );
  $("#td17").html(
    document.getElementById("input11").value !== ""
      ? parseInt(document.getElementById("input11").value)
      : 0
  );
  $("#td18").html(
    document.getElementById("input12").value !== ""
      ? parseInt(document.getElementById("input12").value)
      : 0
  );
  // $("#td19").html((document.getElementById("input13").value !== "" ? parseInt(document.getElementById("input13").value) : 0))
  $("#td22").html(
    document.getElementById("input14").value !== ""
      ? parseInt(document.getElementById("input14").value)
      : 0
  );
  $("#td23").html(
    document.getElementById("input15").value !== ""
      ? parseInt(document.getElementById("input15").value)
      : 0
  );
  $("#td24").html(
    document.getElementById("input16").value !== ""
      ? parseInt(document.getElementById("input16").value)
      : 0
  );
  $("#td25").html(
    document.getElementById("input17").value !== ""
      ? parseInt(document.getElementById("input17").value)
      : 0
  );
  $("#td27").html(
    document.getElementById("input18").value !== ""
      ? parseInt(document.getElementById("input18").value)
      : 0
  );

  $("#t2td1").html(document.getElementById("input19").value);
  $("#t2td2").html(ME);
  $("#t2td7").html(NDM);
  $("#t2td3").html(TEMP);
  $("#t2td4").html(TA);
  $("#t2td5").html(
    document.getElementById("input22").value !== ""
      ? parseInt(document.getElementById("input22").value)
      : 0
  );
  $("#t2td6").html(
    document.getElementById("input23").value !== ""
      ? parseInt(document.getElementById("input23").value)
      : 0
  );
  $("#t2td8").html(
    document.getElementById("input24").value !== ""
      ? parseInt(document.getElementById("input24").value)
      : 0
  );
  $("#t2td9").html(document.getElementById("input25").value);
  $("#t2td10").html(
    document.getElementById("input26").value !== ""
      ? parseInt(document.getElementById("input26").value)
      : 0
  );
  $("#t2td11").html(
    document.getElementById("input27").value !== ""
      ? parseFloat(document.getElementById("input27").value)
      : 0
  );
  $("#t2td12").html(
    document.getElementById("input28").value !== ""
      ? parseFloat(document.getElementById("input28").value)
      : 0
  );
  $("#t2td13").html(
    document.getElementById("input29").value !== ""
      ? parseInt(document.getElementById("input29").value)
      : 0
  );
  $("#t2td14").html(NDMC);
  $("#t2td15").html(
    document.getElementById("input31").value !== ""
      ? parseInt(document.getElementById("input31").value)
      : 0
  );

  $("#c2td1").html(document.getElementById("input19").value !== "" ? dt1 : 0);
  $("#c2td2").html(Number.isNaN(CDCHA) ? 0 : CDCHA.toFixed(2));
  $("#c2td3").html(CTASSU.toFixed(2));
  $("#c2td4").html(CGAR);
  $("#c2td5").html(FRD);
  $("#c2td6").html(Number.isNaN(CDCI) ? 0 : CDCI.toFixed(2));
  $("#c2td7").html(document.getElementById("input25").value !== "" ? dt2 : 0);
  $("#c2td9").html(CTAC.toFixed(2));
  $("#c2td8").html(Number.isNaN(CDCHAC) ? 0 : CDCHAC.toFixed(2));
  $("#c2td10").html(Number.isNaN(CDCC) ? 0 : CDCC.toFixed(2));
  $("#c2td11").html(Number.isNaN(CTDC) ? 0 : CTDC.toFixed(2));
  $("#c2td12").html(Number.isNaN(MPIA) ? 0 : MPIA.toFixed(2));
  $("#c2td13").html(DASSU.toFixed(2));
  $("#c2td14").html(IP.toFixed(2));
  $("#c2td15").html(Number.isNaN(MPCA) ? 0 : MPCA.toFixed(2));
  $("#c2td16").html(MAC.toFixed(2));
  $("#c2td17").html(Number.isNaN(TDM) ? 0 : TDM.toFixed(2));

  // col 3 //

  $("#c3td1").html(NMD1 === 0 ? 0 : CDI.toFixed(2));
  $("#c3td2").html(NMD1 === 0 ? 0 : CDA.toFixed(2));
  $("#c3td3").html(NMD1 === 0 ? 0 : CTDAR.toFixed(2));
  $("#c3td4").html(NMD2 === 0 ? 0 : CDIC.toFixed(2));
  $("#c3td5").html(NMD2 === 0 ? 0 : CDAC.toFixed(2));
  $("#c3td6").html(NMD2 === 0 ? 0 : CTDARC.toFixed(2));
  $("#c3td7").html(Number.isNaN(CTDEP) || CTDEP === "" ? 0 : CTDEP.toFixed(2));
  $("#c3td8").html(VBEM.toFixed(2));
  $("#c3td9").html(PA);
  $("#c3td10").html(CTAPT);
  $("#c3td11").html(FT);
  $("#c3td12").html(ECA);
  $("#c3td13").html(VBAPT);
  $("#c3td14").html(PVE);
  $("#c3td15").html(PVAT);
  $("#c3td16").html(IPV.toFixed(2) < 0 ? 0 : IPV.toFixed(2));
  $("#c3td17").html(BN.toFixed(2));

  Number.isNaN(BN) || BN === "NaN" || BN <= 0
    ? $("#c3td17")
        .removeClass("bg-success bg-default")
        .addClass("bg-danger bg-opacity-50")
    : $("#c3td17")
        .removeClass("bg-danger bg-default")
        .addClass("bg-success bg-opacity-50");
}

function Landc() {
  var j = 34;
  var l = 1;
  var k = 1;
  var nom = [];
  var chrg = [];
  var lyr = [];
  var tot = [];
  var gest = [];
  var imp = [];
  var t1 = $("#taux1").val() !== "" ? parseFloat($("#taux1").val()) : 0;
  var t2 = $("#taux2").val() !== "" ? parseFloat($("#taux2").val()) : 0;
  for (let i = 1; i < 31; i = i + 3) {
    nom[k] = $("#m2input" + i).val() !== "" ? $("#m2input" + i).val() : "";
    chrg[k] =
      $("#m2input" + (i + 1)).val() !== ""
        ? parseInt($("#m2input" + (i + 1)).val())
        : 0;
    lyr[k] =
      $("#m2input" + (i + 2)).val() !== ""
        ? parseInt($("#m2input" + (i + 2)).val())
        : 0;
    tot[k] = parseInt(chrg[k]) + parseInt(lyr[k]);
    gest[k] = $("#chk" + l)[0].checked ? (t1 * tot[k]) / 100 : 0;
    imp[k] = $("#chk" + (l + 1))[0].checked ? (t2 * tot[k]) / 100 : 0;

    $("#r" + k).html(nom[k]);
    $("#c2td" + j).html(gest[k]);
    $("#c2td" + (j + 1)).html(imp[k]);
    $("#c2td" + (j + 2)).html(chrg[k]);
    $("#c2td" + (j + 3)).html(lyr[k]);
    $("#c2td" + (j + 4)).html(tot[k]);
    j = j + 5;
    k = k + 1;
    l = l + 2;
  }

  $("#tgest").html("Gestion \n" + t1 + "%");
  $("#timp").html("Ass_Imp \n" + t2 + "%");
  const sum1 = chrg.reduce((accumulator, value) => {
    return accumulator + value;
  }, 0);
  const sum2 = lyr.reduce((accumulator, value) => {
    return accumulator + value;
  }, 0);
  const sum3 = tot.reduce((accumulator, value) => {
    return accumulator + value;
  }, 0);
  const sum4 = gest.reduce((accumulator, value) => {
    return accumulator + value;
  }, 0);
  const sum5 = imp.reduce((accumulator, value) => {
    return accumulator + value;
  }, 0);
  CTCTA = TXA + CHRA + sum4 * 12 + sum5 * 12;
  const RBM = parseFloat(sum2 * 0.7 - TDM).toFixed(2);
  const MB = parseFloat(sum2 - TDM - CTCTA / 12).toFixed(2);
  const RI = ((sum2 - (DASSU + IP)) * 12 - CTCTA).toFixed(2);
  const RI2 = sum2 * 6 - (DASSU + IP) * 12;
  const RAI = RI2 > 0 ? ((RI2 * 14) / 100).toFixed(2) : 0;
  const RAI30 = RI2 > 0 ? ((RI2 * 30) / 100).toFixed(2) : 0;
  const casha = (
    sum3 * 12 -
    (Number.isNaN(TDM) ? 0 : TDM.toFixed(2)) * 12
  ).toFixed(2);
  const cashm = (
    (sum3 * 12 - (Number.isNaN(TDM) ? 0 : TDM.toFixed(2)) * 12) /
    12
  ).toFixed(2);

  const AM20 = VBAPT / 20;
  const PMPM = $("#input32").val() !== "" ? parseFloat($("#input32").val()) : 0;
  const AM7 = PMPM / 7;

  const AM = AM20 + AM7;
  const PCIR = sum2 * 12 - CTCTA - AM - DASSU - IP;
  const SMR = PCIR > 0 ? (PCIR * 14) / 100 : 0;
  const SMR30 = PCIR > 0 ? (PCIR * 30) / 100 : 0;

  TSyn[0] = sum1;
  TSyn[1] = sum2;
  TSyn[2] = sum3;
  TSyn[4] = TDM;
  TSyn[5] = CTCTA / 12;
  TSyn[6] = TSyn[4] + TSyn[5];
  TSyn[3] = TSyn[2] + TSyn[6];
  TSyn[7] = (RI * 30) / 100 / 12;
  TSyn[8] = TSyn[2] - TSyn[6] - TSyn[7];
  TSyn[9] = (RI * 14) / 100 / 12;
  TSyn[10] = TSyn[2] - TSyn[6] - TSyn[9];
  TSyn[11] = RAI30 / 12;
  TSyn[12] = TSyn[2] - TSyn[6] - TSyn[11];
  TSyn[13] = RAI / 12;
  TSyn[14] = TSyn[2] - TSyn[6] - TSyn[13];
  TSyn[15] = SMR30 / 12;
  TSyn[16] = TSyn[2] - TSyn[4] - TSyn[5] - TSyn[15];
  TSyn[17] = SMR / 12;
  TSyn[18] = TSyn[2] - TSyn[4] - TSyn[5] - TSyn[17];

  const CSG = (parseFloat(RI) * 17.2) / 100;
  const RI14 = parseFloat(RI) > 0 ? (parseFloat(RI) * 14) / 100 : 0;
  const RI30 = parseFloat(RI) > 0 ? (parseFloat(RI) * 30) / 100 : 0;

  const TRBA =
    parseInt(CTAPT) !== 0
      ? (((sum2 * 12) / CTAPT) * 100).toFixed(2)
      : (((sum2 * 12) / 1) * 100).toFixed(2);
  const TRNC =
    parseInt(CTAPT) !== 0
      ? (((sum2 * 12 - CTCTA) / CTAPT) * 100).toFixed(2)
      : (((sum2 * 12 - CTCTA) / 1) * 100).toFixed(2);

  CF[0] = parseFloat(
    (parseFloat(casha) - ((parseFloat(RI) + CSG) * 14) / 100) / 12
  );
  CM[0] = parseFloat(
    parseFloat(casha) - (parseFloat(CSG) + parseFloat(RI14)).toFixed(2)
  );
  CF[1] = parseFloat(
    (parseFloat(casha) - ((parseFloat(RI) + CSG) * 30) / 100) / 12
  );
  CM[1] = parseFloat(
    parseFloat(casha) - (parseFloat(CSG) + parseFloat(RI30)).toFixed(2)
  );
  CF[2] = parseFloat((casha - RAI - CSG) / 12);
  CM[2] = parseFloat(
    parseFloat(casha) - (parseFloat(CSG) + parseFloat(RAI)).toFixed(2)
  );
  CF[3] = parseFloat((casha - RAI30 - CSG) / 12);
  CM[3] = parseFloat(
    parseFloat(casha) - (parseFloat(CSG) + parseFloat(RAI30)).toFixed(2)
  );
  CF[4] = parseFloat((casha - SMR - CSG) / 12);
  CM[4] = parseFloat(
    parseFloat(casha) - (parseFloat(CSG) + parseFloat(SMR)).toFixed(2)
  );
  CF[5] = parseFloat((casha - SMR30 - CSG) / 12);
  CM[5] = parseFloat(
    parseFloat(casha) - (parseFloat(CSG) + parseFloat(SMR30)).toFixed(2)
  );
  var m = 38;
  for (let i = 0; i < 3; i++) {
    CF[i] >= 0
      ? $("#c3td" + m)
          .removeClass("bg-danger bg-default")
          .addClass("bg-success bg-opacity-75")
      : $("#c3td" + m)
          .removeClass("bg-success bg-default")
          .addClass("bg-danger");
    CM[i] >= 0
      ? $("#RCFM" + (i + 1))
          .removeClass("bg-danger bg-default")
          .addClass("bg-success bg-opacity-75")
      : $("#RCFM" + (i + 1))
          .removeClass("bg-success bg-default")
          .addClass("bg-danger");

    m = m + 6;
  }
  m = m + 1;
  for (let i = 3; i < 7; i++) {
    CF[i] >= 0
      ? $("#c3td" + m)
          .removeClass("bg-danger bg-default")
          .addClass("bg-success bg-opacity-75")
      : $("#c3td" + m)
          .removeClass("bg-success bg-default")
          .addClass("bg-danger bg-opacity-75");
    CM[i] >= 0
      ? $("#RCFM" + (i + 1))
          .removeClass("bg-danger bg-default")
          .addClass("bg-success bg-opacity-75")
      : $("#RCFM" + (i + 1))
          .removeClass("bg-success bg-default")
          .addClass("bg-danger bg-opacity-75");

    m = m + 6;
  }

  parseFloat(TRBA) < 8
    ? $("#c2td19")
        .removeClass("bg-success bg-warning bg-default bg-info")
        .addClass("bg-danger bg-opacity-75")
    : parseFloat(TRBA) < 10.01
    ? $("#c2td19")
        .removeClass("bg-success bg-danger bg-default bg-info")
        .addClass("bg-warning bg-opacity-75")
    : parseFloat(TRBA) < 14.1
    ? $("#c2td19")
        .removeClass("bg-success bg-danger bg-default bg-warning")
        .addClass("bg-info bg-opacity-75")
    : $("#c2td19")
        .removeClass("bg-danger bg-warning bg-default bg-info")
        .addClass("bg-success bg-opacity-75");
  parseFloat(TRNC) < 8
    ? $("#c2td20")
        .removeClass("bg-success bg-warning bg-default bg-info")
        .addClass("bg-danger bg-opacity-75")
    : parseFloat(TRBA) < 10.01
    ? $("#c2td20")
        .removeClass("bg-success bg-danger bg-default bg-info")
        .addClass("bg-warning bg-opacity-75")
    : parseFloat(TRBA) < 14.1
    ? $("#c2td20")
        .removeClass("bg-success bg-danger bg-default bg-warning")
        .addClass("bg-info bg-opacity-75")
    : $("#c2td20")
        .removeClass("bg-danger bg-warning bg-default bg-info")
        .addClass("bg-success bg-opacity-75");

  Number.isNaN(MB) || MB === "NaN" || parseFloat(MB) * 36 <= 0
    ? $("#c3td18")
        .removeClass("bg-success bg-default")
        .addClass("bg-danger bg-opacity-50")
    : $("#c3td18")
        .removeClass("bg-danger bg-default")
        .addClass("bg-success bg-opacity-50");
  Number.isNaN(MB) || MB === "NaN" || BN + parseFloat(MB) * 36 <= 0
    ? $("#c3td19")
        .removeClass("bg-success bg-default")
        .addClass("bg-danger bg-opacity-50")
    : $("#c3td19")
        .removeClass("bg-danger bg-default")
        .addClass("bg-success bg-opacity-50");

  Number.isNaN(RBM) || RBM === "NaN" || parseFloat(RBM) <= 0
    ? $("#c2td21")
        .removeClass("bg-success bg-default")
        .addClass("bg-danger bg-opacity-25")
    : $("#c2td21")
        .removeClass("bg-danger bg-default")
        .addClass("bg-success bg-opacity-25");
  Number.isNaN(MB) || MB === "NaN" || parseFloat(MB) <= 0
    ? $("#c2td24")
        .removeClass("bg-success bg-default")
        .addClass("bg-danger bg-opacity-25")
    : $("#c2td24")
        .removeClass("bg-danger bg-default")
        .addClass("bg-success bg-opacity-25");
  Number.isNaN(MB) || MB === "NaN" || parseFloat(MB * 12) <= 0
    ? $("#c2td25")
        .removeClass("bg-success bg-default")
        .addClass("bg-danger bg-opacity-25")
    : $("#c2td25")
        .removeClass("bg-danger bg-default")
        .addClass("bg-success bg-opacity-25");
  Number.isNaN(TDM) || TDM === "NaN" || (sum3 / TDM).toFixed(2) < 2
    ? $("#c2td26")
        .removeClass("bg-success bg-default")
        .addClass("bg-danger bg-opacity-25")
    : $("#c2td26")
        .removeClass("bg-danger bg-default")
        .addClass("bg-success bg-opacity-25");

  $("#td33").html(CTCTA);
  $("#ME1").html(sum2);
  $("#ME2").html(sum1);
  $("#ME3").html(sum3);
  $("#c2td19").html(
    Number.isNaN(TRBA) ? 0 + "%" : parseFloat(TRBA).toFixed(2) + "%"
  );
  $("#c2td20").html(
    Number.isNaN(TRNC) ? 0 + "%" : parseFloat(TRNC).toFixed(2) + "%"
  );
  $("#c2td21").html(
    Number.isNaN(RBM) || RBM === "NaN" ? 0 : parseFloat(RBM).toFixed(2)
  );
  $("#c2td22").html(sum3);
  $("#c2td23").html(Number.isNaN(TDM) ? 0 : (sum2 - TDM).toFixed(2));
  $("#c2td24").html(
    Number.isNaN(MB) || MB === "NaN" ? 0 : parseFloat(MB).toFixed(2)
  );
  $("#c2td25").html(
    Number.isNaN(MB) || MB === "NaN" ? 0 : (MB * 12).toFixed(2)
  );
  $("#c2td26").html(Number.isNaN(TDM) ? 0 : (sum2 / TDM).toFixed(2));
  $("#c2td84").html(sum4);
  $("#c2td85").html(sum5);
  $("#c2td86").html(sum1);
  $("#c2td87").html(sum2);
  $("#c2td88").html(sum3);
  $("#c2td89").html((sum4 * 12).toFixed(2));
  $("#c2td90").html((sum5 * 12).toFixed(2));
  $("#c2td91").html((sum1 * 12).toFixed(2));
  $("#c2td92").html((sum2 * 12).toFixed(2));
  $("#c2td93").html((sum3 * 12).toFixed(2));
  $("#c2td94").html((sum3 * 12 - sum1 * 12 - sum5 * 12 - sum4 * 12).toFixed(2));

  $("#c3td18").html(
    Number.isNaN(MB) || MB === "NaN" ? 0 : (MB * 36).toFixed(2)
  );
  $("#c3td19").html(
    Number.isNaN(MB) || MB === "NaN" ? 0 : (BN + MB * 36).toFixed(2)
  );
  $("#c3td20").html(sum2 * 82);
  $("#c3td21").html(sum2 * 90);
  $("#c3td22").html((CTAPT / 85).toFixed(2));
  $("#c3td25").html(((CTAPT * 15) / 100).toFixed(2));
  $("#c3td26").html(((CTAPT * 15) / 100 / 12).toFixed(2));
  $("#c3td27").html(((CTAPT * 13) / 100).toFixed(2));
  $("#c3td28").html(((CTAPT * 13) / 100 / 12).toFixed(2));
  $("#c3td29").html(((CTAPT * 10) / 100).toFixed(2));
  $("#c3td30").html(((CTAPT * 10) / 100 / 12).toFixed(2));
  $("#c3td31").html(RI);
  $(".RI1").html(RI);
  $("#c3td32").html((parseFloat(RI) / 12).toFixed(2));
  $(".RI2").html((parseFloat(RI) / 12).toFixed(2));
  $("#c3td33").html(casha);
  $(".CLCC").html(casha);
  $("#c3td34").html(cashm);
  $("#c3td35").html((RI * 14) / 100 < 0 ? 0 : ((RI * 14) / 100).toFixed(2));
  // $("#c3td36").html((parseFloat(casha)-((parseFloat(RI)+CSG)*14/100)).toFixed(2))
  // $("#c3td38").html(CF[0].toFixed(2))
  // $("#c3td39").html((CTCTA/12).toFixed(2))
  $("#c3td40").html(CM[0].toFixed(2));
  $("#c3td41").html((RI * 30) / 100 < 0 ? 0 : ((RI * 30) / 100).toFixed(2));
  // $("#c3td42").html((parseFloat(casha)-((parseFloat(RI)+CSG)*30/100)).toFixed(2))
  // $("#c3td44").html(CF[1].toFixed(2))
  // $("#c3td45").html((CTCTA/12).toFixed(2))
  $("#c3td46").html(CM[1].toFixed(2));
  $("#c3td47").html(RAI < 0 ? 0 : RAI);
  // $("#c3td48").html((casha-RAI-CSG).toFixed(2))
  // $("#c3td50").html(CF[2].toFixed(2))
  // $("#c3td51").html((CTCTA/12).toFixed(2))
  $("#c3td52").html(CM[2].toFixed(2));
  $("#c3td53").html(RAI30 < 0 ? 0 : RAI30);
  // $("#c3td54").html((casha - RAI30 -CSG).toFixed(2))
  $("#c3td57").html(CF[3].toFixed(2));
  $("#c3td58").html((CTCTA / 12).toFixed(2));
  $("#c3td59").html(CM[3].toFixed(2));
  $("#c3td60").html(SMR < 0 ? 0 : SMR.toFixed(2));
  // $("#c3td61").html((casha-SMR-CSG).toFixed(2))
  // $("#c3td63").html(CF[4].toFixed(2))
  // $("#c3td64").html((CTCTA/12).toFixed(2))
  $("#c3td65").html(CM[4].toFixed(2));
  $("#c3td66").html(SMR30.toFixed(2));
  // $("#c3td67").html((casha-SMR30-CSG).toFixed(2))
  // $("#c3td69").html(CF[5].toFixed(2))
  // $("#c3td70").html((CTCTA/12).toFixed(2))
  $("#c3td71").html(CM[5].toFixed(2));

  $("#CFM1").html((CM[0] / 12).toFixed(2));
  $("#CFM2").html((CM[1] / 12).toFixed(2));
  $("#CFM3").html((CM[2] / 12).toFixed(2));
  $("#CFM4").html((CM[3] / 12).toFixed(2));
  $("#CFM5").html((CM[4] / 12).toFixed(2));
  $("#CFM6").html((CM[5] / 12).toFixed(2));

  $("#td20").html((sum4 * 12).toFixed(2));
  $("#td21").html(sum5 * 12);

  $("#td15").html(CHRA + sum5 * 12 + sum4 * 12);

  $("#CSG1").html(CSG.toFixed(2));
  $(".CRDS1").html(CSG.toFixed(2));
  // $(".CRDS2").html((CSG/12).toFixed(2))
  $("#CSG2").html((CSG / 12).toFixed(2));
  $("#CSG3").html((parseFloat(CSG) + parseFloat(RI14)).toFixed(2));
  $("#CSG5").html((parseFloat(CSG) + parseFloat(RI30)).toFixed(2));
  $("#CSG7").html((parseFloat(CSG) + parseFloat(RAI)).toFixed(2));
  $("#CSG9").html((parseFloat(CSG) + parseFloat(RAI30)).toFixed(2));
  $("#CSG11").html((parseFloat(CSG) + parseFloat(SMR)).toFixed(2));
  $("#CSG13").html((parseFloat(CSG) + parseFloat(SMR30)).toFixed(2));

  data = [
    {
      values: [TSyn[4], TSyn[5], TSyn[9], TSyn[10]],
      labels: [
        "Échéance-crédit",
        "Charges-propriétaire",
        "Impôts 14%",
        "Cash-flow",
      ],
      type: "pie",
    },
  ];

  data2 = [
    {
      values: [TSyn[4], TSyn[5], TSyn[13], TSyn[14]],
      labels: [
        "Échéance-crédit",
        "Charges-propriétaire",
        "Si-meublé-14%",
        "Cash-flow",
      ],
      type: "pie",
    },
  ];

  data3 = [
    {
      values: [TSyn[4], TSyn[5], TSyn[17], TSyn[18]],
      labels: [
        "Échéance-crédit",
        "Charges-propriétaire",
        "Si réel 14%",
        "Cash-flow",
      ],
      type: "pie",
    },
  ];

  data4 = [
    {
      values: [TSyn[4], TSyn[5], TSyn[7], TSyn[8]],
      labels: [
        "Échéance-crédit",
        "Charges-propriétaire",
        "Impôts 30%",
        "Cash-flow",
      ],
      type: "pie",
    },
  ];

  data5 = [
    {
      values: [TSyn[4], TSyn[5], TSyn[11], TSyn[12]],
      labels: [
        "Échéance-crédit",
        "Charges-propriétaire",
        "Si-meublé-30%",
        "Cash-flow",
      ],
      type: "pie",
    },
  ];

  data6 = [
    {
      values: [TSyn[4], TSyn[5], TSyn[15], TSyn[16]],
      labels: [
        "Échéance-crédit",
        "Charges-propriétaire",
        "Si réel 30%",
        "Cash-flow",
      ],
      type: "pie",
    },
  ];

  layout = {
    height: 350,
    width: 400,
  };

  Plotly.newPlot("myDiv", data, layout);
  Plotly.newPlot("myDiv2", data2, layout);
  Plotly.newPlot("myDiv3", data3, layout);
  Plotly.newPlot("myDiv4", data4, layout);
  Plotly.newPlot("myDiv5", data6, layout);
  Plotly.newPlot("myDiv6", data5, layout);
  return data, data2, data3, data4, data5, data6, layout;
}

window.onload = function () {
  const myModal = document.getElementById("myModal");
  myModal.addEventListener("show.bs.modal", () => {
    Calc();
    Landc();
  });
};

function Sums() {
  let totc = 0;
  let totl = 0;
  let v1 = 0,
    v2 = 0;
  for (let i = 2; i < 31; i = i + 3) {
    v1 =
      $("#m2input" + i).val() === "" ||
      $("#m2input" + i).val() === "NaN" ||
      Number.isNaN($("#m2input" + i).val())
        ? 0
        : parseFloat($("#m2input" + i).val());
    v2 =
      $("#m2input" + (i + 1)).val() === "" ||
      $("#m2input" + (i + 1)).val() === "NaN" ||
      Number.isNaN($("#m2input" + (i + 1)).val())
        ? 0
        : parseFloat($("#m2input" + (i + 1)).val());
    totc = totc + v1;
    totl = totl + v2;
  }

  $("#m2input32").val(totc);
  $("#m2input33").val(totl);
}

export {
  Calc,
  put,
  Frais_notr,
  valeur_at,
  Landc,
  data,
  data2,
  data3,
  data4,
  data5,
  data6,
  layout,
  CT,
  PDM,
  Sums,
};
