import React from "react";
import "./App.css";
import {
  Frais_notr,
  valeur_at,
  data,
  data2,
  data3,
  data4,
  data5,
  data6,
  layout,
  CT,
  PDM,
  Sums,
} from "./Components/script";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Plot from "react-plotly.js";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

function App() {
  function Call1() {
    valeur_at();
    Frais_notr();
    CT();
  }
  return (
    <div className="App">
      <div className="row justify-content-md-center">
        <div className="col col-lg-4 fs-3 fw-semibold text-primary text-opacity-50 bg-info bg-opacity-25 py-2">
          {" "}
          Détecteur de bonnes affaires{" "}
          <p
            className="fs-6 text-dark text-opacity-50"
            style={{ display: "inline" }}
          >
            V3.3.2
          </p>{" "}
        </div>
      </div>
      <div className="container">
        <div className="row"></div>
        <div className="row">
          <form className="row g-2 mb-2">
            <div className="col-md-3 border bg-warning bg-gradient p-2 text-dark bg-opacity-10">
              <label htmlFor="input1" className="form-label">
                Surface m² (avant travaux)
              </label>
              <input
                type="number"
                className="form-control"
                id="input1"
                onChange={valeur_at}
              />
              <label htmlFor="input2" className="form-label">
                Valeur du m² (avant travaux)
              </label>
              <input
                type="number"
                className="form-control"
                id="input2"
                disabled
              />
              <label htmlFor="input3" className="form-label">
                Surface m² (après travaux)
              </label>
              <input
                type="number"
                className="form-control"
                id="input3"
                onChange={PDM}
              />
              <label htmlFor="input4" className="form-label">
                Valeur du m² du marché
              </label>
              <input
                type="number"
                className="form-control"
                id="input4"
                onChange={PDM}
              />
              <label htmlFor="inputPDM1" className="form-label">
                Prix du marché
              </label>
              <input
                type="number"
                className="form-control"
                id="inputPDM1"
                disabled
              />
            </div>
            <div className="col-md-2 border bg-info p-2 text-dark bg-opacity-10">
              <label htmlFor="input5" className="form-label">
                Prix de vente
              </label>
              <input
                type="number"
                className="form-control"
                id="input5"
                onChange={Call1}
              />
              <label htmlFor="input6" className="form-label">
                Frais de notaire{" "}
              </label>
              <input
                type="number"
                className="form-control"
                id="input6"
                disabled
                onChange={CT}
              />
              {/* <label htmlFor="input7" className="form-label">Autres Frais</label>
                <input type="number" className="form-control" id="input7" /> */}
              <label htmlFor="input8" className="form-label">
                Autres frais d'achats
              </label>
              <input
                type="number"
                className="form-control"
                id="input8"
                onChange={CT}
              />
              <label htmlFor="input9" className="form-label">
                Frais Travaux
              </label>
              <input
                type="number"
                className="form-control"
                id="input9"
                onChange={CT}
              />
              <label htmlFor="inputCT1" className="form-label">
                Coûts Total
              </label>
              <input
                type="number"
                className="form-control"
                id="inputCT1"
                disabled
              />
            </div>
            <div className="col-md-3 border bg-info bg-gradient p-2 text-dark bg-opacity-25">
              <label htmlFor="input132" className="form-label">
                Prix mobilier pour meublé
              </label>
              <input type="number" className="form-control" id="input32" />
              <label htmlFor="input10" className="form-label">
                Charges copropriété (annuelle)
              </label>
              <input type="number" className="form-control" id="input10" />
              <label htmlFor="input11" className="form-label">
                Entretien
              </label>
              <input type="number" className="form-control" id="input11" />
              <label htmlFor="input12" className="form-label">
                Assurance
              </label>
              <input type="number" className="form-control" id="input12" />
              {/* <label htmlFor="input13" className="form-label">Frais divers annuel</label>
                <input type="number" className="form-control" id="input13" /> */}
              <label htmlFor="input14" className="form-label">
                Électricité commun
              </label>
              <input type="number" className="form-control" id="input14" />
              <label htmlFor="input15" className="form-label">
                Eaux{" "}
              </label>
              <input type="number" className="form-control" id="input15" />
              <label htmlFor="input16" className="form-label">
                Coût de la garantie
              </label>
              <input type="number" className="form-control" id="input16" />
              <label htmlFor="input17" className="form-label">
                Frais de dossier
              </label>
              <input type="number" className="form-control" id="input17" />
              <label htmlFor="input18" className="form-label">
                Taxe foncière
              </label>
              <input type="number" className="form-control" id="input18" />
            </div>
            <div className="col-md-2 border bg-info p-2 text-dark bg-opacity-10">
              <div className="alert alert-primary" role="alert">
                Emprunt immobilier
              </div>
              <label htmlFor="input19" className="form-label">
                Date de l'emprunt
              </label>
              <input type="date" className="form-control" id="input19" />
              <label htmlFor="input20" className="form-label">
                Taux de l'emprunt(%)
              </label>
              <input type="number" className="form-control" id="input20" />
              <label htmlFor="input21" className="form-label">
                Taux de l'assurance(%)
              </label>
              <input type="number" className="form-control" id="input21" />
              <label htmlFor="input22" className="form-label">
                Durée de l'emprunt
              </label>
              <input type="number" className="form-control" id="input22" />
              <label htmlFor="input23" className="form-label">
                Apport
              </label>
              <input type="number" className="form-control" id="input23" />
              <label htmlFor="input24" className="form-label">
                Nbr de Mois de décalage avant 1er remboursement
              </label>
              <input type="number" className="form-control" id="input24" />
            </div>
            <div className="col-md-2 border bg-warning bg-gradient p-2 text-dark bg-opacity-10">
              <div className="alert alert-primary" role="alert">
                Emprunt à la consommation
              </div>
              <label htmlFor="input25" className="form-label">
                Date de début d'emprunt
              </label>
              <input type="date" className="form-control" id="input25" />
              <label htmlFor="input26" className="form-label">
                Montant de l'emprunt{" "}
              </label>
              <input type="number" className="form-control" id="input26" />
              <label htmlFor="input27" className="form-label">
                Taux de l'emprunt(%)
              </label>
              <input type="number" className="form-control" id="input27" />
              <label htmlFor="input28" className="form-label">
                Taux de l'assurance(%)
              </label>
              <input type="number" className="form-control" id="input28" />
              <label htmlFor="input29" className="form-label">
                Durée de l'emprunt
              </label>
              <input type="number" className="form-control" id="input29" />
              <label htmlFor="input31" className="form-label">
                Nbr de Mois de décalage avant 1er remboursement
              </label>
              <input type="number" className="form-control" id="input31" />
            </div>
          </form>
        </div>
        <br />
        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
          <button
            type="button"
            className="btn btn-primary text-center"
            data-bs-toggle="modal"
            data-bs-target="#myModal2"
          >
            Loyer &amp; Charges
          </button>
          <button
            type="button"
            className="btn btn-primary text-center"
            data-bs-toggle="modal"
            data-bs-target="#myModal"
          >
            Calculateur
          </button>
        </div>
        <br />
        <br />
        <p className="text-center text-muted">
          Developpé par{" "}
          <a
            href="https://hn-itsolutions.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="list-group-item list-group-item-action list-group-item-info"
          >
            Hn ITSolutions
          </a>{" "}
        </p>
      </div>
      {/* The Modal 1 */}
      <div className="modal fade" id="myModal">
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            {/* Modal body */}
            <div className="modal-body">
              <Tabs
                defaultActiveKey="Calcmain"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab
                  eventKey="Calcmain"
                  title="Accueil"
                  id="Calcmain"
                  className="fullheightfixed"
                >
                  <Container fluid>
                    <Row>
                      <Col>
                        <table className="table table-hover table-sm table-bordered">
                          <thead className="table-secondary">
                            <tr>
                              <th
                                scope="col"
                                colSpan={2}
                                className="text-center bg-success p-2 text-dark bg-opacity-50"
                              >
                                Données entrée immeuble
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row" className="fs-6 fw-normal">
                                Surface m² (avant travaux)
                              </th>
                              <td id="td1" className="text-end" />
                            </tr>
                            <tr>
                              <th scope="row" className="fs-6 fw-normal w-75">
                                Valeur du m² (avant travaux)
                              </th>
                              <td id="td2" className="text-end" />
                            </tr>
                            <tr>
                              <th scope="row" className="fs-6 fw-normal">
                                Surface m² (après travaux)
                              </th>
                              <td id="td3" className="text-end" />
                            </tr>
                            <tr>
                              <th scope="row" className="fs-6 fw-normal">
                                Valeur du m² du marché
                              </th>
                              <td id="td4" className="text-end" />
                            </tr>
                            <tr>
                              <th scope="row" className="fs-6 fw-normal">
                                Prix d’achat selon M2 avant travaux{" "}
                              </th>
                              <td id="td5" className="text-end" />
                            </tr>
                            <tr>
                              <th scope="row" className="fs-6 fw-normal">
                                Prix du marché selon M2 après travaux{" "}
                              </th>
                              <td id="td6" className="text-end" />
                            </tr>
                            <tr>
                              <th scope="row" className="fs-6 fw-normal">
                                Prix appartement
                              </th>
                              <td id="td7" className="text-end" />
                            </tr>
                            <tr className="bg-secondary bg-opacity-50">
                              <th scope="row" className="fs-6 fw-normal">
                                Frais supplémentaires d'achat
                              </th>
                              <td id="td8" className="text-end" />
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fst-italic fw-normal text-end"
                              >
                                dont Frais de notaires
                              </th>
                              <td id="td9" className="text-end" />
                            </tr>
                            {/* <tr>
                      <th scope="row" className="fs-6 fst-italic fw-normal text-end">dont Frais enchères </th>
                      <td id="td10" className="text-end" />
                    </tr> */}
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal fst-italic  text-end"
                              >
                                dont Autres frais d'achats
                              </th>
                              <td id="td11" className="text-end" />
                            </tr>
                            <tr className="bg-secondary bg-opacity-25">
                              <th scope="row" className="fs-6 fw-semibold">
                                Coût total (avant travaux)
                              </th>
                              <td id="td12" className="text-end" />
                            </tr>
                            <tr>
                              <th scope="row" className="fs-6 fw-normal">
                                Frais Travaux
                              </th>
                              <td id="td13" className="text-end" />
                            </tr>
                            <tr className="bg-secondary bg-opacity-50">
                              <th
                                scope="row"
                                className="fs-6 fw-bold text-center"
                              >
                                Coût total (après travaux)
                              </th>
                              <td id="td14" className="text-end" />
                            </tr>
                            <tr className="bg-secondary bg-opacity-25">
                              <th scope="row" className="fs-6 fw-semibold">
                                Charges annuelles
                              </th>
                              <td id="td15" className="text-end" />
                            </tr>
                            <tr>
                              <th scope="row" className="fs-6 fw-normal">
                                Charges copropriété
                              </th>
                              <td id="td16" className="text-end" />
                            </tr>
                            <tr>
                              <th scope="row" className="fs-6 fw-normal">
                                Entretien
                              </th>
                              <td id="td17" className="text-end" />
                            </tr>
                            <tr>
                              <th scope="row" className="fs-6 fw-normal">
                                Assurance
                              </th>
                              <td id="td18" className="text-end" />
                            </tr>
                            <tr>
                              <th scope="row" className="fs-6 fw-normal">
                                Frais de gestion (agence){" "}
                              </th>
                              <td id="td20" className="text-end" />
                            </tr>
                            <tr>
                              <th scope="row" className="fs-6 fw-normal">
                                Assurance loyer impayé
                              </th>
                              <td id="td21" className="text-end" />
                            </tr>
                            <tr>
                              <th scope="row" className="fs-6 fw-normal">
                                Électricité commun
                              </th>
                              <td id="td22" className="text-end" />
                            </tr>
                            <tr>
                              <th scope="row" className="fs-6 fw-normal">
                                Eaux
                              </th>
                              <td id="td23" className="text-end" />
                            </tr>
                            <tr className="bg-secondary bg-opacity-25">
                              <th scope="row" className="fs-6 fw-semibold">
                                Taxes annuelles
                              </th>
                              <td id="td26" className="text-end" />
                            </tr>
                            <tr>
                              <th scope="row" className="fs-6 fw-normal">
                                Taxe foncière
                              </th>
                              <td id="td27" className="text-end" />
                            </tr>
                            <tr className="bg-secondary bg-opacity-25">
                              <th scope="row" className="fs-6 fw-semibold">
                                Coût total des charges et taxes annuelles
                              </th>
                              <td id="td33" className="text-end" />
                            </tr>
                          </tbody>
                        </table>
                        <table className="table table-hover table-sm table-bordered">
                          <thead className="table-secondary">
                            <tr>
                              <th
                                scope="col"
                                colSpan={2}
                                className="text-center bg-success p-2 text-dark bg-opacity-50"
                              >
                                Données entrée Emprunts
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-semibold text-center bg-success text-dark bg-opacity-25"
                                colSpan={2}
                              >
                                Informations Emprunt immobilier
                              </th>
                            </tr>
                            <tr>
                              <th scope="row" className="fs-6 fw-normal w-75">
                                Date de l'emprunt
                              </th>
                              <td id="t2td1" className="text-end" />
                            </tr>
                            <tr>
                              <th scope="row" className="fs-6 fw-normal">
                                Montant de l'emprunt
                              </th>
                              <td id="t2td2" className="text-end" />
                            </tr>
                            <tr>
                              <th scope="row" className="fs-6 fw-normal">
                                Taux de l'emprunt(%){" "}
                              </th>
                              <td id="t2td3" className="text-end" />
                            </tr>
                            <tr>
                              <th scope="row" className="fs-6 fw-normal">
                                Taux de l'assurance(%)
                              </th>
                              <td id="t2td4" className="text-end" />
                            </tr>
                            <tr>
                              <th scope="row" className="fs-6 fw-normal">
                                Durée de l'emprunt
                              </th>
                              <td id="t2td5" className="text-end" />
                            </tr>
                            <tr>
                              <th scope="row" className="fs-6 fw-normal">
                                Nombre de Mensualités
                              </th>
                              <td id="t2td7" className="text-end" />
                            </tr>
                            <tr>
                              <th scope="row" className="fs-6 fw-normal">
                                Apport
                              </th>
                              <td id="t2td6" className="text-end" />
                            </tr>

                            <tr>
                              <th scope="row" className="fs-6 fw-normal">
                                Nbr de Mois de décalage avant 1er remboursement
                              </th>
                              <td id="t2td8" className="text-end" />
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-semibold text-center bg-success text-dark bg-opacity-25"
                                colSpan={2}
                              >
                                Informations Emprunt Consommation
                              </th>
                            </tr>
                            <tr>
                              <th scope="row" className="fs-6 fw-normal">
                                Date de début d'emprunt
                              </th>
                              <td id="t2td9" className="text-end" />
                            </tr>
                            <tr>
                              <th scope="row" className="fs-6 fw-normal">
                                Montant de l'emprunt
                              </th>
                              <td id="t2td10" className="text-end" />
                            </tr>
                            <tr>
                              <th scope="row" className="fs-6 fw-normal">
                                Taux de l'emprunt(%)
                              </th>
                              <td id="t2td11" className="text-end" />
                            </tr>
                            <tr>
                              <th scope="row" className="fs-6 fw-normal">
                                Taux de l'assurance(%)
                              </th>
                              <td id="t2td12" className="text-end" />
                            </tr>
                            <tr>
                              <th scope="row" className="fs-6 fw-normal">
                                Durée de l'emprunt
                              </th>
                              <td id="t2td13" className="text-end" />
                            </tr>
                            <tr>
                              <th scope="row" className="fs-6 fw-normal">
                                Nombre de Mensualités
                              </th>
                              <td id="t2td14" className="text-end" />
                            </tr>
                            <tr>
                              <th scope="row" className="fs-6 fw-normal">
                                Nbr de Mois de décalage avant 1er remboursement
                              </th>
                              <td id="t2td15" className="text-end" />
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                      <Col>
                        <Table bordered hover size="sm">
                          <thead className="table-secondary">
                            <tr>
                              <th
                                scope="col"
                                colSpan={2}
                                className="text-center bg-success p-2 text-dark bg-opacity-50"
                              >
                                Informations Prêts
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal bg-success p-2 text-dark bg-opacity-25"
                                colSpan={2}
                              >
                                Prêt immobilier
                              </th>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                date de fin d'emprunt
                              </th>
                              <td id="c2td1" className="text-end w-25"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                coût du crédit (hors assurance)
                              </th>
                              <td id="c2td2" className="text-end w-25"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Coût total de l'assurance
                              </th>
                              <td id="c2td3" className="text-end w-25"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Coût de la garantie
                              </th>
                              <td id="c2td4" className="text-end w-25"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Frais de dossier du crédit
                              </th>
                              <td id="c2td5" className="text-end w-25"></td>
                            </tr>
                            <tr className="bg-secondary bg-opacity-10">
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Coût du crédit immo
                              </th>
                              <td id="c2td6" className="text-end w-25"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal bg-success p-2 text-dark bg-opacity-25"
                                colSpan={2}
                              >
                                Prêt consommation
                              </th>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                date de fin d'emprunt
                              </th>
                              <td id="c2td7" className="text-end w-25"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                coût du crédit (hors assurance)
                              </th>
                              <td id="c2td8" className="text-end w-25"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Coût total de l'assurance
                              </th>
                              <td id="c2td9" className="text-end w-25"></td>
                            </tr>
                            <tr className="bg-secondary bg-opacity-10">
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Coût du crédit conso
                              </th>
                              <td id="c2td10" className="text-end w-25"></td>
                            </tr>
                            <tr className="bg-secondary bg-opacity-25">
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                cout total des crédits (immo+conso)
                              </th>
                              <td id="c2td11" className="text-end w-25"></td>
                            </tr>
                          </tbody>
                        </Table>
                        <Table bordered hover size="sm">
                          <thead className="table-secondary">
                            <tr>
                              <th
                                scope="col"
                                colSpan={2}
                                className="text-center bg-success p-2 text-dark bg-opacity-50"
                              >
                                Information financières
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal bg-success p-2 text-dark bg-opacity-25"
                                colSpan={2}
                              >
                                Mensualités à payer
                              </th>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Mensualité prêt immo à payer
                              </th>
                              <td id="c2td12" className="text-end w-25"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Dont assurance
                              </th>
                              <td id="c2td13" className="text-end w-25"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Intérêts
                              </th>
                              <td id="c2td14" className="text-end w-25"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Mensualités prêt conso à payer
                              </th>
                              <td id="c2td15" className="text-end w-25"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Dont assurance
                              </th>
                              <td id="c2td16" className="text-end w-25"></td>
                            </tr>
                            <tr className="bg-secondary bg-opacity-10">
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Total des mensualités (immo + conso)
                              </th>
                              <td id="c2td17" className="text-end w-25"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal bg-success p-2 text-dark bg-opacity-25"
                                colSpan={2}
                              >
                                Mensualités encaissées
                              </th>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Loyers
                              </th>
                              <td id="ME1" className="text-end w-25"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Charges
                              </th>
                              <td id="ME2" className="text-end w-25"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Total
                              </th>
                              <td id="ME3" className="text-end w-25"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal bg-success p-2 text-dark bg-opacity-25"
                                colSpan={2}
                              >
                                Rentabilités
                              </th>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Taux de rentabilité brut annuel
                              </th>
                              <td id="c2td19" className="text-end w-25"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Taux de rentabilité net de charges (hors impôt)
                              </th>
                              <td id="c2td20" className="text-end w-25"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal bg-success p-2 text-dark bg-opacity-25"
                                colSpan={2}
                              >
                                Résultat
                              </th>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal bg-success p-2 text-dark bg-opacity-10"
                                colSpan={2}
                              >
                                Résultat brut réel (hors impôt)
                              </th>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Encaissement mensuel
                              </th>
                              <td id="c2td22" className="text-end w-25"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Brut (moins crédit)
                              </th>
                              <td id="c2td23" className="text-end w-25"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Mensuel brut (moins frais et crédit)
                              </th>
                              <td id="c2td24" className="text-end w-25"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Annuel
                              </th>
                              <td id="c2td25" className="text-end w-25"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal bg-success p-2 text-dark bg-opacity-10"
                                colSpan={2}
                              >
                                Résutat brut pour la banque (Régle des 70%)
                              </th>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Résultat Brut Mensuel selon la Banque
                              </th>
                              <td id="c2td21" className="text-end w-25"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Un euro investi{" "}
                              </th>
                              <td id="c2td26" className="text-end w-25"></td>
                            </tr>
                          </tbody>
                        </Table>
                        <Table bordered hover size="sm">
                          <thead className="table-secondary">
                            <tr>
                              <th
                                scope="col"
                                colSpan={6}
                                className="text-center bg-success p-2 text-dark bg-opacity-50"
                              >
                                Données entrée Location appartement
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start w-50"
                              ></th>
                              <td className="text-center" id="tgest">
                                Gestion
                              </td>
                              <td className="text-center" id="timp">
                                Ass_Imp
                              </td>
                              <td className="text-center">Charge</td>
                              <td className="text-center">Loyer</td>
                              <td className="text-center">Total</td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                id="r1"
                                className="fs-6 fw-normal text-start w-50"
                              ></th>
                              <td id="c2td34" className="text-center"></td>
                              <td id="c2td35" className="text-center"></td>
                              <td id="c2td36" className="text-center"></td>
                              <td id="c2td37" className="text-center"></td>
                              <td id="c2td38" className="text-center"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                id="r2"
                                className="fs-6 fw-normal text-start w-50"
                              ></th>
                              <td id="c2td39" className="text-center"></td>
                              <td id="c2td40" className="text-center"></td>
                              <td id="c2td41" className="text-center"></td>
                              <td id="c2td42" className="text-center"></td>
                              <td id="c2td43" className="text-center"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                id="r3"
                                className="fs-6 fw-normal text-start w-50"
                              ></th>
                              <td id="c2td44" className="text-center"></td>
                              <td id="c2td45" className="text-center"></td>
                              <td id="c2td46" className="text-center"></td>
                              <td id="c2td47" className="text-center"></td>
                              <td id="c2td48" className="text-center"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                id="r4"
                                className="fs-6 fw-normal text-start w-50"
                              ></th>
                              <td id="c2td49" className="text-center"></td>
                              <td id="c2td50" className="text-center"></td>
                              <td id="c2td51" className="text-center"></td>
                              <td id="c2td52" className="text-center"></td>
                              <td id="c2td53" className="text-center"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                id="r5"
                                className="fs-6 fw-normal text-start w-50"
                              ></th>
                              <td id="c2td54" className="text-center"></td>
                              <td id="c2td55" className="text-center"></td>
                              <td id="c2td56" className="text-center"></td>
                              <td id="c2td57" className="text-center"></td>
                              <td id="c2td58" className="text-center"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                id="r6"
                                className="fs-6 fw-normal text-start w-50"
                              ></th>
                              <td id="c2td59" className="text-center"></td>
                              <td id="c2td60" className="text-center"></td>
                              <td id="c2td61" className="text-center"></td>
                              <td id="c2td62" className="text-center"></td>
                              <td id="c2td63" className="text-center"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                id="r7"
                                className="fs-6 fw-normal text-start w-50"
                              ></th>
                              <td id="c2td64" className="text-center"></td>
                              <td id="c2td65" className="text-center"></td>
                              <td id="c2td66" className="text-center"></td>
                              <td id="c2td67" className="text-center"></td>
                              <td id="c2td68" className="text-center"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                id="r8"
                                className="fs-6 fw-normal text-start w-50"
                              ></th>
                              <td id="c2td69" className="text-center"></td>
                              <td id="c2td70" className="text-center"></td>
                              <td id="c2td71" className="text-center"></td>
                              <td id="c2td72" className="text-center"></td>
                              <td id="c2td73" className="text-center"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                id="r9"
                                className="fs-6 fw-normal text-start w-50"
                              ></th>
                              <td id="c2td74" className="text-center"></td>
                              <td id="c2td75" className="text-center"></td>
                              <td id="c2td76" className="text-center"></td>
                              <td id="c2td77" className="text-center"></td>
                              <td id="c2td78" className="text-center"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                id="r10"
                                className="fs-6 fw-normal text-start w-50"
                              ></th>
                              <td id="c2td79" className="text-center"></td>
                              <td id="c2td80" className="text-center"></td>
                              <td id="c2td81" className="text-center"></td>
                              <td id="c2td82" className="text-center"></td>
                              <td id="c2td83" className="text-center"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                id="r11"
                                className="fs-6 fw-normal text-start w-50"
                              >
                                {" "}
                                Total
                              </th>
                              <td id="c2td84" className="text-center"></td>
                              <td id="c2td85" className="text-center"></td>
                              <td id="c2td86" className="text-center"></td>
                              <td id="c2td87" className="text-center"></td>
                              <td id="c2td88" className="text-center"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                id="r12"
                                className="fs-6 fw-normal text-start w-50"
                              >
                                {" "}
                                Annuel{" "}
                              </th>
                              <td id="c2td89" className="text-center"></td>
                              <td id="c2td90" className="text-center"></td>
                              <td id="c2td91" className="text-center"></td>
                              <td id="c2td92" className="text-center"></td>
                              <td id="c2td93" className="text-center"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                id="r13"
                                className="fs-6 fw-normal text-start"
                                colSpan={5}
                              >
                                {" "}
                                Loyer net annuel (charges et assurances loyer
                                déduit){" "}
                              </th>

                              <td
                                id="c2td94"
                                className="text-center  w-25"
                              ></td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                      <Col>
                        <Table bordered hover size="sm">
                          <thead className="table-secondary">
                            <tr>
                              <th
                                scope="col"
                                colSpan={3}
                                className="text-center bg-success p-2 text-dark bg-opacity-50"
                              >
                                Coûts des délais de prêts
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal bg-success p-2 text-dark bg-opacity-25"
                                colSpan={3}
                              >
                                Délai avant remboursement du Prêt immobilier
                              </th>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                coût délai avant remboursement (coût des
                                intérêts)
                              </th>
                              <td
                                id="c3td1"
                                className="text-end w-25"
                                colSpan={2}
                              ></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                coût de l'assurance (délai avant remboursement)
                              </th>
                              <td
                                id="c3td2"
                                className="text-end w-25"
                                colSpan={2}
                              ></td>
                            </tr>
                            <tr className="bg-secondary bg-opacity-10">
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                coût total du délai avant remboursement
                              </th>
                              <td
                                id="c3td3"
                                className="text-end w-25"
                                colSpan={2}
                              ></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal bg-success p-2 text-dark bg-opacity-25"
                                colSpan={3}
                              >
                                Délai avant remboursement du Prêt Consommation
                              </th>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                coût du délai avant remboursement
                              </th>
                              <td
                                id="c3td4"
                                className="text-end w-25"
                                colSpan={2}
                              ></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                coût de l'assurance (délai avant remboursement)
                              </th>
                              <td
                                id="c3td5"
                                className="text-end w-25"
                                colSpan={2}
                              ></td>
                            </tr>
                            <tr className="bg-secondary bg-opacity-10">
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                coût total du délai avant remboursement
                              </th>
                              <td
                                id="c3td6"
                                className="text-end w-25"
                                colSpan={2}
                              ></td>
                            </tr>
                            <tr className="bg-secondary bg-opacity-25">
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Cout total des délais engagés sur les prêts
                              </th>
                              <td
                                id="c3td7"
                                className="text-end w-25"
                                colSpan={2}
                              ></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal bg-success p-2 text-dark bg-opacity-25"
                                colSpan={3}
                              >
                                Estimation de l'immeuble
                              </th>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Valeur du bien estimée prix du marché{" "}
                              </th>
                              <td
                                id="c3td8"
                                className="text-end w-25"
                                colSpan={2}
                              ></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Prix d'achat
                              </th>
                              <td
                                id="c3td9"
                                className="text-end w-25"
                                colSpan={2}
                              ></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Travaux
                              </th>
                              <td
                                id="c3td11"
                                className="text-end w-25"
                                colSpan={2}
                              ></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Frais de notaire
                              </th>
                              <td
                                id="FDN1"
                                className="text-end w-25"
                                colSpan={2}
                              ></td>
                            </tr>
                            <tr className="bg-secondary bg-opacity-25">
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Coût d'achat (prix +frais +travaux)
                              </th>
                              <td
                                id="c3td10"
                                className="text-end w-25"
                                colSpan={2}
                              ></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Valeur du bien prix de marché
                              </th>
                              <td
                                id="c3td13"
                                className="text-end w-25"
                                colSpan={2}
                              ></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Ecart coût d'achat et valeur estimée (plus
                                value)
                              </th>
                              <td
                                id="c3td12"
                                className="text-end w-25"
                                colSpan={2}
                              ></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Plus value estimée avant travaux
                              </th>
                              <td
                                id="c3td14"
                                className="text-end w-25"
                                colSpan={2}
                              ></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Plus value après travaux
                              </th>
                              <td
                                id="c3td15"
                                className="text-end w-25"
                                colSpan={2}
                              ></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Impôts plus value (30%)
                              </th>
                              <td
                                id="c3td16"
                                className="text-end w-25"
                                colSpan={2}
                              ></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Bénéfice net
                              </th>
                              <td
                                id="c3td17"
                                className="text-end w-25"
                                colSpan={2}
                              ></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Cash-flow 3 ans (hors crédit)
                              </th>
                              <td
                                id="c3td18"
                                className="text-end w-25"
                                colSpan={2}
                              ></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Total Benf + cash-flow 3 ans
                              </th>
                              <td
                                id="c3td19"
                                className="text-end w-25"
                                colSpan={2}
                              ></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal bg-success p-2 text-dark bg-opacity-25"
                                colSpan={3}
                              >
                                Prix idéal pour le bien selon ma méthode
                              </th>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Prix idéal par rapport au loyer (loyer*82)
                              </th>
                              <td
                                id="c3td20"
                                className="text-start w-25"
                                colSpan={2}
                              ></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start w-75"
                              >
                                Prix idéal par rapport au loyer (loyer*90)
                              </th>
                              <td
                                id="c3td21"
                                className="text-start "
                                colSpan={2}
                              ></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Loyer idéal par rapport au prix (loyer*90)
                              </th>
                              <td
                                id="c3td22"
                                className="text-start w-25"
                                colSpan={2}
                              ></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal bg-success p-2 text-dark bg-opacity-25"
                                colSpan={3}
                              ></th>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              ></th>
                              <td id="c3td23" className="text-center">
                                An
                              </td>
                              <td id="c3td24" className="text-center">
                                Mois
                              </td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Loyer idéal 15%
                              </th>
                              <td id="c3td25" className="text-center"></td>
                              <td id="c3td26" className="text-center"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Loyer idéal 13%
                              </th>
                              <td id="c3td27" className="text-center"></td>
                              <td id="c3td28" className="text-center"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Loyer idéal 10%
                              </th>
                              <td id="c3td29" className="text-center"></td>
                              <td id="c3td30" className="text-center"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal bg-success p-2 text-dark bg-opacity-25"
                                colSpan={3}
                              ></th>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              ></th>
                              <td className="text-center">An</td>
                              <td className="text-center">Mois</td>
                            </tr>
                            <tr className="bg-secondary bg-opacity-25">
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Revenu imposable (loyer-(intérêt + assurance +
                                frais))
                              </th>
                              <td id="c3td31" className="text-center"></td>
                              <td id="c3td32" className="text-center"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Cash (loyer + Charges - crédit)
                              </th>
                              <td
                                id="c3td33"
                                className="bg-secondary bg-opacity-25 text-center"
                              ></td>
                              <td className="text-center"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                CSG - CRDS (17.2%)
                              </th>
                              <td
                                id="CSG1"
                                className="bg-info bg-opacity-25 text-center"
                              ></td>
                              <td className="text-center"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal bg-success p-2 text-dark bg-opacity-25"
                                colSpan={3}
                              >
                                Impôts IR 14%
                              </th>
                            </tr>
                            <tr className="bg-secondary bg-opacity-25">
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Revenu imposable (loyer-(intérêt + assurance +
                                frais))
                              </th>
                              <td className="text-center RI1"></td>
                              <td className="text-center RI2"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Cash (loyer + Charges - crédit)
                              </th>
                              <td className="bg-secondary bg-opacity-25 text-center CLCC"></td>
                              <td className="text-center"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Impôts IR 14%
                              </th>
                              <td
                                id="c3td35"
                                className="text-center bg-info bg-opacity-25"
                              ></td>
                              <td className="text-center"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                CSG-CRDS (17,2%)
                              </th>
                              <td className="text-center CRDS1 bg-info bg-opacity-25"></td>
                              <td className="text-center CRDS2"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start "
                              >
                                Total impôts + CRDS
                              </th>
                              <td
                                id="CSG3"
                                className="text-center bg-info bg-opacity-25"
                              ></td>
                              <td id="CSG4" className="text-center"></td>
                            </tr>
                            {/* <tr>
                            <th scope="row" className="fs-6 fw-normal text-start">Charges</th>
                            <td id="c3td39" className="text-center"></td>
                            <td className="text-center"></td>
                          </tr>  */}
                            {/* <tr>
                            <th scope="row" className="fs-6 fw-normal text-start">résultat après impôts hors charges</th>
                            <td id="c3td38" className="text-center"></td>
                            <td id="c3td37" className="text-center"></td>
                          </tr>   */}
                            <tr id="RCFM1">
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Cash-flow net après impôts
                              </th>
                              <td id="c3td40" className="text-center"></td>
                              <td id="CFM1" className="text-center"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal bg-success p-2 text-dark bg-opacity-25"
                                colSpan={3}
                              >
                                Impots IR 30%
                              </th>
                            </tr>
                            <tr className="bg-secondary bg-opacity-25">
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Revenu imposable (loyer-(intérêt + assurance +
                                frais))
                              </th>
                              <td className="text-center RI1"></td>
                              <td className="text-center RI2"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Cash (loyer + Charges - crédit)
                              </th>
                              <td className="bg-secondary bg-opacity-25 text-center CLCC"></td>
                              <td className="text-center"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Impôts IR 30%
                              </th>
                              <td
                                id="c3td41"
                                className="text-center bg-info bg-opacity-25"
                              ></td>
                              <td id="c3td42" className="text-center"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                CSG-CRDS (17,2%)
                              </th>
                              <td className="text-center CRDS1 bg-info bg-opacity-25"></td>
                              <td className="text-center CRDS2"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Total impôts + CRDS
                              </th>
                              <td
                                id="CSG5"
                                className="text-center bg-info bg-opacity-25"
                              ></td>
                              <td id="CSG6" className="text-center"></td>
                            </tr>
                            {/* <tr>
                            <th scope="row" className="fs-6 fw-normal text-start">Charges</th>
                            <td id="c3td45" className="text-center"></td>
                            <td className="text-center"></td>
                          </tr>                                                               
                          <tr>
                            <th scope="row" className="fs-6 fw-normal text-start">résultat après impôts hors charges</th>
                            <td id="c3td44" className="text-center"></td>
                            <td id="c3td43" className="text-center"></td>
                          </tr>                                     */}
                            <tr id="RCFM2">
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Cash-flow net après impôts
                              </th>
                              <td id="c3td46" className="text-center"></td>
                              <td id="CFM2" className="text-center"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal bg-success p-2 text-dark bg-opacity-25"
                                colSpan={3}
                              >
                                Impôts si meublé au forfait 50%
                              </th>
                            </tr>
                            <tr className="bg-secondary bg-opacity-25">
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Revenu imposable (loyer-(intérêt + assurance +
                                frais))
                              </th>
                              <td className="text-center RI1"></td>
                              <td className="text-center RI2"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Cash (loyer + Charges - crédit)
                              </th>
                              <td className="bg-secondary bg-opacity-25 text-center CLCC"></td>
                              <td className="text-center"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Impôts meublé au forfait 14%
                              </th>
                              <td
                                id="c3td47"
                                className="text-center bg-info bg-opacity-25"
                              ></td>
                              <td id="c3td48" className="text-center"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                CSG-CRDS (17,2%)
                              </th>
                              <td className="text-center CRDS1 bg-info bg-opacity-25"></td>
                              <td className="text-center CRDS2"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Total impôts + CRDS
                              </th>
                              <td
                                id="CSG7"
                                className="text-center bg-info bg-opacity-25"
                              ></td>
                              <td id="CSG8" className="text-center"></td>
                            </tr>
                            {/* <tr>
                            <th scope="row" className="fs-6 fw-normal text-start">Charges</th>
                            <td id="c3td51" className="text-center"></td>
                            <td className="text-center"></td>
                          </tr>                                                                                                           
                          <tr>
                            <th scope="row" className="fs-6 fw-normal text-start">résultat après impôts hors charges</th>
                            <td id="c3td50" className="text-center"></td>
                            <td id="c3td49" className="text-center"></td>
                          </tr>                                     */}
                            <tr id="RCFM3">
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Cash-flow net après impôts
                              </th>
                              <td id="c3td52" className="text-center"></td>
                              <td id="CFM3" className="text-center"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal bg-success p-2 text-dark bg-opacity-25"
                                colSpan={3}
                              >
                                Impots 30%
                              </th>
                            </tr>
                            <tr className="bg-secondary bg-opacity-25">
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Revenu imposable (loyer-(intérêt + assurance +
                                frais))
                              </th>
                              <td className="text-center RI1"></td>
                              <td className="text-center RI2"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Cash (loyer + Charges - crédit)
                              </th>
                              <td className="bg-secondary bg-opacity-25 text-center CLCC"></td>
                              <td className="text-center"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Impôts meublé au forfait 30%
                              </th>
                              <td
                                id="c3td53"
                                className="text-center bg-info bg-opacity-25"
                              ></td>
                              <td id="c3td54" className="text-center"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                CSG-CRDS (17,2%)
                              </th>
                              <td className="text-center CRDS1 bg-info bg-opacity-25"></td>
                              <td className="text-center CRDS2"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Total impôts + CRDS
                              </th>
                              <td
                                id="CSG9"
                                className="text-center bg-info bg-opacity-25"
                              ></td>
                              <td id="CSG10" className="text-center"></td>
                            </tr>
                            {/* <tr>
                            <th scope="row" className="fs-6 fw-normal text-start">Charges</th>
                            <td id="c3td58" className="text-center"></td>
                            <td className="text-center"></td>
                          </tr>                                                                                                              
                          <tr>
                            <th scope="row" className="fs-6 fw-normal text-start">résultat après impôts hors charges</th>
                            <td id="c3td57" className="text-center"></td>
                            <td id="c3td56" className="text-center"></td>
                          </tr>                                     */}
                            <tr id="RCFM4">
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Cash-flow net après impôts
                              </th>
                              <td id="c3td59" className="text-center"></td>
                              <td id="CFM4" className="text-center"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal bg-success p-2 text-dark bg-opacity-25"
                                colSpan={3}
                              >
                                Impôts au réel simplifié (faux car sans les
                                frais km, gestion, etc)
                              </th>
                            </tr>
                            <tr className="bg-secondary bg-opacity-25">
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Revenu imposable (loyer-(intérêt + assurance +
                                frais))
                              </th>
                              <td className="text-center RI1"></td>
                              <td className="text-center RI2"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Cash (loyer + Charges - crédit)
                              </th>
                              <td className="bg-secondary bg-opacity-25 text-center CLCC"></td>
                              <td className="text-center"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Impôts meublé réel 14%
                              </th>
                              <td
                                id="c3td60"
                                className="text-center bg-info bg-opacity-25"
                              ></td>
                              <td id="c3td61" className="text-center"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                CSG-CRDS (17,2%)
                              </th>
                              <td className="text-center CRDS1  bg-info bg-opacity-25"></td>
                              <td className="text-center CRDS2"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Total impôts + CRDS
                              </th>
                              <td
                                id="CSG11"
                                className="text-center  bg-info bg-opacity-25"
                              ></td>
                              <td id="CSG12" className="text-center"></td>
                            </tr>
                            {/* <tr>
                            <th scope="row" className="fs-6 fw-normal text-start">Charges</th>
                            <td id="c3td64" className="text-center"></td>
                            <td className="text-center"></td>
                          </tr>                                                                                       
                          <tr>
                            <th scope="row" className="fs-6 fw-normal text-start">résultat après impôts hors charges</th>
                            <td id="c3td63" className="text-center"></td>
                            <td id="c3td62" className="text-center"></td>
                          </tr>                                     */}
                            <tr id="RCFM5">
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Cash-flow net après impôts
                              </th>
                              <td id="c3td65" className="text-center"></td>
                              <td id="CFM5" className="text-center"></td>
                            </tr>
                            <tr className="bg-warning">
                              <td colSpan={3}></td>
                            </tr>
                            <tr className="bg-secondary bg-opacity-25">
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Revenu imposable (loyer-(intérêt + assurance +
                                frais))
                              </th>
                              <td className="text-center RI1"></td>
                              <td className="text-center RI2"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Cash (loyer + Charges - crédit)
                              </th>
                              <td className="bg-secondary bg-opacity-25 text-center CLCC"></td>
                              <td className="text-center"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Impôts meublé réel 30%
                              </th>
                              <td
                                id="c3td66"
                                className="text-center bg-info bg-opacity-25"
                              ></td>
                              <td id="c3td67" className="text-center"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                CSG-CRDS (17,2%)
                              </th>
                              <td className="text-center CRDS1 bg-info bg-opacity-25"></td>
                              <td className="text-center CRDS2"></td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Total impôts + CRDS
                              </th>
                              <td
                                id="CSG13"
                                className="text-center bg-info bg-opacity-25"
                              ></td>
                              <td id="CSG14" className="text-center"></td>
                            </tr>
                            {/* <tr>
                            <th scope="row" className="fs-6 fw-normal text-start">Charges</th>
                            <td id="c3td70" className="text-center"></td>
                            <td className="text-center"></td>
                          </tr>                                                                                                            
                          <tr>
                            <th scope="row" className="fs-6 fw-normal text-start">résultat après impôts hors charges</th>
                            <td id="c3td69" className="text-center"></td>
                            <td id="c3td68" className="text-center"></td>
                          </tr>                                     */}
                            <tr id="RCFM6">
                              <th
                                scope="row"
                                className="fs-6 fw-normal text-start"
                              >
                                Cash-flow net après impôts
                              </th>
                              <td id="c3td71" className="text-center"></td>
                              <td id="CFM6" className="text-center"></td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </Container>
                </Tab>
                <Tab eventKey="Cambrt" title="Graphiques" id="Cambrt">
                  <Container fluid>
                    <Row>
                      <Col>
                        <div id="myDiv">
                          <Plot data={data} layout={layout} />
                        </div>
                      </Col>
                      <Col>
                        <div id="myDiv4">
                          <Plot data={data2} layout={layout} />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div id="myDiv3">
                          <Plot data={data5} layout={layout} />
                        </div>
                      </Col>
                      <Col>
                        <div id="myDiv5">
                          <Plot data={data6} layout={layout} />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div id="myDiv2">
                          <Plot data={data3} layout={layout} />
                        </div>
                      </Col>
                      <Col>
                        <div id="myDiv6">
                          <Plot data={data4} layout={layout} />
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Tab>
              </Tabs>
            </div>
            {/* Modal footer */}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* The Modal 2 */}
      <div className="modal fade" id="myModal2">
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            {/* Modal body */}
            <div className="modal-body">
              <table
                className="table table-hover table-sm table-bordered"
                id="lc"
              >
                <thead className="table-secondary">
                  <tr>
                    <th scope="col" colSpan={5} className="text-center">
                      Loyer et Charges
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-start" style={{ width: "30%" }}></td>
                    <td className="text-center">
                      Gestion (%){" "}
                      <input
                        type="text"
                        id="taux1"
                        className="w-100 border border-0 text-center"
                        defaultValue={6}
                      />
                    </td>
                    <td className="text-center">
                      Loyer Imp (%){" "}
                      <input
                        type="text"
                        id="taux2"
                        className="w-100 border border-0 text-center"
                        defaultValue={2.5}
                      />
                    </td>
                    <td className="text-center">Charges</td>
                    <td className="text-center">Loyer</td>
                  </tr>
                  <tr>
                    <td className="text-start" id="m2td1">
                      <input
                        type="text"
                        id="m2input1"
                        className="w-100 border border-0"
                      />
                    </td>
                    <td id="m2td2" className="d-flex justify-content-center">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue
                          id="chk1"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="chk1"
                        ></label>
                      </div>
                    </td>
                    <td id="m2td3">
                      <div className="form-check justify-content-center d-flex">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue
                          id="chk2"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="chk2"
                        ></label>
                      </div>
                    </td>
                    <td className="text-center" id="m2td4">
                      <input
                        type="number"
                        id="m2input2"
                        style={{ width: "4em" }}
                        max="9999"
                        className="border border-0"
                        onChange={Sums}
                      />
                    </td>
                    <td className="text-center" id="m2td5">
                      <input
                        style={{ width: "4em" }}
                        max="9999"
                        type="number"
                        id="m2input3"
                        className="border border-0"
                        onChange={Sums}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start" id="m2td6">
                      <input
                        type="text"
                        id="m2input4"
                        className="w-100 border border-0"
                      />
                    </td>
                    <td id="m2td7" className="d-flex justify-content-center">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue
                          id="chk3"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="chk3"
                        ></label>
                      </div>
                    </td>
                    <td id="m2td8">
                      <div className="form-check justify-content-center d-flex">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue
                          id="chk4"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="chk4"
                        ></label>
                      </div>
                    </td>
                    <td className="text-center" id="m2td9">
                      <input
                        style={{ width: "4em" }}
                        max="9999"
                        type="number"
                        id="m2input5"
                        className="border border-0"
                        onChange={Sums}
                      />
                    </td>
                    <td className="text-center" id="m2td10">
                      <input
                        style={{ width: "4em" }}
                        max="9999"
                        type="number"
                        id="m2input6"
                        className="border border-0"
                        onChange={Sums}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start" id="m2td11">
                      <input
                        type="text"
                        id="m2input7"
                        className="w-100 border border-0"
                      />
                    </td>
                    <td id="m2td12" className="d-flex justify-content-center">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue
                          id="chk5"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="chk5"
                        ></label>
                      </div>
                    </td>
                    <td id="m2td13">
                      <div className="form-check justify-content-center d-flex">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue
                          id="chk6"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="chk6"
                        ></label>
                      </div>
                    </td>
                    <td className="text-center" id="m2td14">
                      <input
                        style={{ width: "4em" }}
                        max="9999"
                        type="number"
                        id="m2input8"
                        className="border border-0"
                        onChange={Sums}
                      />
                    </td>
                    <td className="text-center" id="m2td15">
                      <input
                        style={{ width: "4em" }}
                        max="9999"
                        type="number"
                        id="m2input9"
                        className="border border-0"
                        onChange={Sums}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start" id="m2td16">
                      <input
                        type="text"
                        id="m2input10"
                        className="w-100 border border-0"
                      />
                    </td>
                    <td id="m2td17" className="d-flex justify-content-center">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue
                          id="chk7"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="chk7"
                        ></label>
                      </div>
                    </td>
                    <td id="m2td18">
                      <div className="form-check justify-content-center d-flex">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue
                          id="chk8"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="chk8"
                        ></label>
                      </div>
                    </td>
                    <td className="text-center" id="m2td19">
                      <input
                        style={{ width: "4em" }}
                        max="9999"
                        type="number"
                        id="m2input11"
                        className="border border-0"
                        onChange={Sums}
                      />
                    </td>
                    <td className="text-center" id="m2td20">
                      <input
                        style={{ width: "4em" }}
                        max="9999"
                        type="number"
                        id="m2input12"
                        className="border border-0"
                        onChange={Sums}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start" id="m2td21">
                      <input
                        type="text"
                        id="m2input13"
                        className="w-100 border border-0"
                      />
                    </td>
                    <td id="m2td22" className="d-flex justify-content-center">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue
                          id="chk9"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="chk9"
                        ></label>
                      </div>
                    </td>
                    <td id="m2td23">
                      <div className="form-check justify-content-center d-flex">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue
                          id="chk10"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="chk10"
                        ></label>
                      </div>
                    </td>
                    <td className="text-center" id="m2td24">
                      <input
                        style={{ width: "4em" }}
                        max="9999"
                        type="number"
                        id="m2input14"
                        className="border border-0"
                        onChange={Sums}
                      />
                    </td>
                    <td className="text-center" id="m2td25">
                      <input
                        style={{ width: "4em" }}
                        max="9999"
                        type="number"
                        id="m2input15"
                        className="border border-0"
                        onChange={Sums}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start" id="m2td26">
                      <input
                        type="text"
                        id="m2input16"
                        className="w-100 border border-0"
                      />
                    </td>
                    <td id="m2td27" className="d-flex justify-content-center">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue
                          id="chk11"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="chk11"
                        ></label>
                      </div>
                    </td>
                    <td id="m2td28">
                      <div className="form-check justify-content-center d-flex">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue
                          id="chk12"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="chk12"
                        ></label>
                      </div>
                    </td>
                    <td className="text-center" id="m2td29">
                      <input
                        style={{ width: "4em" }}
                        max="9999"
                        type="number"
                        id="m2input17"
                        className="border border-0"
                        onChange={Sums}
                      />
                    </td>
                    <td className="text-center" id="m2td30">
                      <input
                        style={{ width: "4em" }}
                        max="9999"
                        type="number"
                        id="m2input18"
                        className="border border-0"
                        onChange={Sums}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start" id="m2td31">
                      <input
                        type="text"
                        id="m2input19"
                        className="w-100 border border-0"
                      />
                    </td>
                    <td id="m2td32" className="d-flex justify-content-center">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue
                          id="chk13"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="chk13"
                        ></label>
                      </div>
                    </td>
                    <td id="m2td33">
                      <div className="form-check justify-content-center d-flex">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue
                          id="chk14"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="chk14"
                        ></label>
                      </div>
                    </td>
                    <td className="text-center" id="m2td34">
                      <input
                        style={{ width: "4em" }}
                        max="9999"
                        type="number"
                        id="m2input20"
                        className="border border-0"
                        onChange={Sums}
                      />
                    </td>
                    <td className="text-center" id="m2td35">
                      <input
                        style={{ width: "4em" }}
                        max="9999"
                        type="number"
                        id="m2input21"
                        className="border border-0"
                        onChange={Sums}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start" id="m2td36">
                      <input
                        type="text"
                        id="m2input22"
                        className="w-100 border border-0"
                      />
                    </td>
                    <td id="m2td37" className="d-flex justify-content-center">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue
                          id="chk15"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="chk15"
                        ></label>
                      </div>
                    </td>
                    <td id="m2td38">
                      <div className="form-check justify-content-center d-flex">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue
                          id="chk16"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="chk16"
                        ></label>
                      </div>
                    </td>
                    <td className="text-center" id="m2td39">
                      <input
                        style={{ width: "4em" }}
                        max="9999"
                        type="number"
                        id="m2input23"
                        className="border border-0"
                        onChange={Sums}
                      />
                    </td>
                    <td className="text-center" id="m2td40">
                      <input
                        style={{ width: "4em" }}
                        max="9999"
                        type="number"
                        id="m2input24"
                        className="border border-0"
                        onChange={Sums}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start" id="m2td41">
                      <input
                        type="text"
                        id="m2input25"
                        className="w-100 border border-0"
                      />
                    </td>
                    <td id="m2td42" className="d-flex justify-content-center">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue
                          id="chk17"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="chk17"
                        ></label>
                      </div>
                    </td>
                    <td id="m2td43">
                      <div className="form-check justify-content-center d-flex">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue
                          id="chk18"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="chk18"
                        ></label>
                      </div>
                    </td>
                    <td className="text-center" id="m2td44">
                      <input
                        style={{ width: "4em" }}
                        max="9999"
                        type="number"
                        id="m2input26"
                        className="border border-0"
                        onChange={Sums}
                      />
                    </td>
                    <td className="text-center" id="m2td45">
                      <input
                        style={{ width: "4em" }}
                        max="9999"
                        type="number"
                        id="m2input27"
                        className="border border-0"
                        onChange={Sums}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start" id="m2td46">
                      <input
                        type="text"
                        id="m2input28"
                        className="w-100 border border-0"
                      />
                    </td>
                    <td id="m2td47" className="d-flex justify-content-center">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue
                          id="chk19"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="chk19"
                        ></label>
                      </div>
                    </td>
                    <td id="m2td48">
                      <div className="form-check justify-content-center d-flex">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue
                          id="chk20"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="chk20"
                        ></label>
                      </div>
                    </td>
                    <td className="text-center" id="m2td49">
                      <input
                        style={{ width: "4em" }}
                        max="9999"
                        type="number"
                        id="m2input29"
                        className="border border-0"
                        onChange={Sums}
                      />
                    </td>
                    <td className="text-center" id="m2td50">
                      <input
                        style={{ width: "4em" }}
                        max="9999"
                        type="number"
                        id="m2input30"
                        className="border border-0"
                        onChange={Sums}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start" id="m2td51" colSpan={3}>
                      <input
                        type="text"
                        id="m2input31"
                        className="w-100 border border-0"
                        defaultValue={"Total"}
                      />
                    </td>

                    <td className="text-center" id="m2td52">
                      <input
                        style={{ width: "5em" }}
                        max="99999"
                        type="number"
                        id="m2input32"
                        className="border border-0"
                      />
                    </td>
                    <td className="text-center" id="m2td53">
                      <input
                        style={{ width: "5em" }}
                        max="99999"
                        type="number"
                        id="m2input33"
                        className="border border-0"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success"
                data-bs-dismiss="modal"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
